body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.text-center {
    text-align: center;
}

.d-inline-block {
    display: inline-block;
}
.d-block {
    display: block;
}
.lh1 {
    line-height: 1;
}
.no-underline {
    text-decoration: none;
}
.min-field-width {
    min-width: 80px;
}

.data-grid-container {
    display: block;
    overflow: auto;
}
